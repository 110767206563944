var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex w-full"},[_c('img',{staticClass:"mr-5",attrs:{"src":_vm.logo,"height":"55"}}),_vm._m(0)]),_c('div',{staticClass:"w-full flex"},[_c('div',{staticClass:"w-2/12"},[_vm._v("Nomor")]),_c('div',{staticClass:"w-4"},[_vm._v(":")]),_c('div',{staticClass:"w-8/12"},[_vm._v(_vm._s(_vm.code))])]),_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"mt-3"},[_vm._v("Berikut ini adalah berita acara serah terima produk PT. Sinarmas Distribusi Nusantara kepada ............... (tuliskan nama principal/vendor/dll) untuk .......... (tuliskan keperluannya) yang akan diproses pada:")]),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_c('div',[_vm._v(" Demikian berita acara ini dibuat untuk dipergunakan sebagai dokumentasi atas proses serah terima produk. ")]),_vm._m(9),_c('div',{staticClass:"w-full text-center"},[_vm._v("Disaksikan oleh")]),_vm._m(10),_vm._m(11)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title text-left"},[_c('div',[_c('strong',[_vm._v("SDN-FRM-LOG.02-011")])]),_c('div',[_c('strong',[_vm._v("BERITA ACARA SERAH TERIMA PRODUK")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full flex"},[_c('div',{staticClass:"w-2/12"},[_vm._v("Tempat")]),_c('div',{staticClass:"w-4"},[_vm._v(":")]),_c('div',{staticClass:"w-8/12"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full flex"},[_c('div',{staticClass:"w-2/12"},[_vm._v("Perihal")]),_c('div',{staticClass:"w-4"},[_vm._v(":")]),_c('div',{staticClass:"w-8/12"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full flex"},[_c('div',{staticClass:"w-2/12"},[_vm._v("Lampiran")]),_c('div',{staticClass:"w-4"},[_vm._v(":")]),_c('div',{staticClass:"w-8/12"},[_vm._v("        lembar")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full flex"},[_c('div',{staticClass:"w-2/12"},[_vm._v("Hari/Tanggal")]),_c('div',{staticClass:"w-4"},[_vm._v(":")]),_c('div',{staticClass:"w-8/12"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full flex"},[_c('div',{staticClass:"w-2/12"},[_vm._v("Waktu")]),_c('div',{staticClass:"w-4"},[_vm._v(":")]),_c('div',{staticClass:"w-8/12"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full flex"},[_c('div',{staticClass:"w-2/12"},[_vm._v("Total Quantity")]),_c('div',{staticClass:"w-4"},[_vm._v(":")]),_c('div',{staticClass:"w-8/12"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full flex"},[_c('div',{staticClass:"w-2/12"},[_vm._v("Berat")]),_c('div',{staticClass:"w-4"},[_vm._v(":")]),_c('div',{staticClass:"w-8/12"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3"},[_c('u',[_c('strong',[_vm._v("Note")])]),_c('div',[_vm._v(" Wajib melampirkan rincian produknya (Kode Produk, Nama Produk, SKU, ED, Total Qty (Pcs/Carton), Keterangan Produk) ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full flex mt-3"},[_c('div',{staticClass:"w-1/2 text-center"},[_c('div',[_vm._v("Pihak Principal/Vendor/dll")]),_c('div',{staticClass:"mt-10"},[_vm._v("(. . . . . . . . . . . . . . . . . . . . .)")])]),_c('div',{staticClass:"w-1/2 text-center"},[_c('div',[_vm._v("WHS Supervisor")]),_c('div',{staticClass:"mt-10"},[_vm._v("(. . . . . . . . . . . . . . . . . . . . .)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full flex"},[_c('div',{staticClass:"w-1/3 text-center"},[_c('div',[_vm._v("BLC/OS")]),_c('div',{staticClass:"mt-10"},[_vm._v("(. . . . . . . . . . . . . . . . . . . . .)")])]),_c('div',{staticClass:"w-1/3 text-center"},[_c('div',[_vm._v("BAM/BAS")]),_c('div',{staticClass:"mt-10"},[_vm._v("(. . . . . . . . . . . . . . . . . . . . .)")])]),_c('div',{staticClass:"w-1/3 text-center"},[_c('div',[_vm._v("APJ*")]),_c('div',{staticClass:"mt-10"},[_vm._v("(. . . . . . . . . . . . . . . . . . . . .)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('u',[_c('strong',[_vm._v("Note:")])]),_c('div',[_vm._v("* Jika produk obat maka wajib ditandatangani oleh APJ")]),_c('ul',[_c('li',[_vm._v("Wajib melampirkan list produk yang diserahterimakan")]),_c('li',[_vm._v("Wajib melampirkan foto saat serah terima produk")]),_c('li',[_vm._v("Wajib melampirkan PO Retur, Surat Pengajuan Retur (SPR), dan dokumen lainnya")])])])
}]

export { render, staticRenderFns }