<style scoped>
    /* Set the page size to A4 and the orientation to landscape */
    @page {
        size: letter portrait;
    }

    /* Set the font size to 12px for printing */
    @media print {
        body {
            font-size: 12px;
        }
    }

    .title {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 20px;
    }

    table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }

    #no-border {
        border: none;
    }

    td, th.bordered {
        /* border: 1px solid #000000; */
        border-top: 1px solid #000000;
        border-bottom: 1px solid #000000;
        border-right: 1px solid #000000;
        border-left: 1px solid #000000;
        text-align: left;
        padding: 8px;
    }

    td.no-border-flat {
        border-top: none;
        border-bottom: none;
    }

    th.no-border {
        border: none;
    }

    .remove_border tr th{
        border: none;
    }

    #table-list {
        margin-top: 80px;
    }

    .info {
        margin-top: 20px;
        width: 40%;
        float: left;
        margin-bottom: 20px;
    }
</style>
<style>
    /* Set the font size to 12px for printing */
    @media print {
        header, footer, .print-button, #content-area * :not(.content-wrapper *) {
            display: none !important;
            visibility: hidden !important;
        }

        .router-view,
        .router-content {
            padding: 0 !important;
            margin: 0 !important;
        }
    }

</style>

<template>
    <div>
        <div class="flex w-full">
            <img :src="logo" height="55" class="mr-5">
            <div class="title text-left">
                <div>
                    <strong>SDN-FRM-LOG.02-011</strong>
                </div>
                <div>
                    <strong>BERITA ACARA SERAH TERIMA PRODUK</strong>
                </div>
            </div>
        </div>
        <!-- <table>
            <thead>
                <tr>
                    <th class="py-0">Nomor</th>
                    <th class="py-0" colspan="5">: {{ code }}</th>
                </tr>
                <tr>
                    <th class="py-0">Tempat</th>
                    <th class="py-0" colspan="5">: {{ code }}</th>
                </tr>
                <tr>
                    <th class="py-0">Perihal</th>
                    <th class="py-0" colspan="5">: {{ code }}</th>
                </tr>
                <tr>
                    <th class="py-0">Lampiran</th>
                    <th class="py-0" colspan="5">: {{ code }} Lembar</th>
                </tr>
                <tr>
                    <th class="py-2" colspan="6">Berikut ini adalah berita acara serah terima produk PT. Sinarmas Distribusi Nusantara kepada ............... (tuliskan nama principal/vendor/dll) untuk .......... (tuliskan keperluannya) yang akan diproses pada:</th>
                </tr>
                <tr>
                    <th class="py-0">Hari/Tanggal</th>
                    <th class="py-0" colspan="5">: {{ code }}</th>
                </tr>
                <tr>
                    <th class="py-0">Waktu</th>
                    <th class="py-0" colspan="5">: {{ code }}</th>
                </tr>
                <tr>
                    <th class="py-0">Total Quantity</th>
                    <th class="py-0" colspan="5">: {{ code }}</th>
                </tr>
                <tr>
                    <th class="py-0">Berat</th>
                    <th class="py-0" colspan="5">: {{ code }}</th>
                </tr>
            </thead>
        </table> -->
        <div class="w-full flex">
            <div class="w-2/12">Nomor</div>
            <div class="w-4">:</div>
            <div class="w-8/12">{{ code }}</div>
        </div>
        <div class="w-full flex">
            <div class="w-2/12">Tempat</div>
            <div class="w-4">:</div>
            <div class="w-8/12"></div>
        </div>
        <div class="w-full flex">
            <div class="w-2/12">Perihal</div>
            <div class="w-4">:</div>
            <div class="w-8/12"></div>
        </div>
        <div class="w-full flex">
            <div class="w-2/12">Lampiran</div>
            <div class="w-4">:</div>
            <div class="w-8/12">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; lembar</div>
        </div>
        <div class="mt-3">Berikut ini adalah berita acara serah terima produk PT. Sinarmas Distribusi Nusantara kepada ............... (tuliskan nama principal/vendor/dll) untuk .......... (tuliskan keperluannya) yang akan diproses pada:</div>
        <div class="w-full flex">
            <div class="w-2/12">Hari/Tanggal</div>
            <div class="w-4">:</div>
            <div class="w-8/12"></div>
        </div>
        <div class="w-full flex">
            <div class="w-2/12">Waktu</div>
            <div class="w-4">:</div>
            <div class="w-8/12"></div>
        </div>
        <div class="w-full flex">
            <div class="w-2/12">Total Quantity</div>
            <div class="w-4">:</div>
            <div class="w-8/12"></div>
        </div>
        <div class="w-full flex">
            <div class="w-2/12">Berat</div>
            <div class="w-4">:</div>
            <div class="w-8/12"></div>
        </div>
        <div class="mt-3">
            <u><strong>Note</strong></u>
            <div>
                Wajib melampirkan rincian produknya (Kode Produk, Nama Produk, SKU, ED, Total Qty (Pcs/Carton), Keterangan Produk)
            </div>
        </div>
        <div>
            Demikian berita acara ini dibuat untuk dipergunakan sebagai dokumentasi atas proses serah terima produk.
        </div>
        <div class="w-full flex mt-3">
            <div class="w-1/2 text-center">
                <div>Pihak Principal/Vendor/dll</div>
                <div class="mt-10">(. . . . . . . . . . . . . . . . . . . . .)</div>
            </div>
            <div class="w-1/2 text-center">
                <div>WHS Supervisor</div>
                <div class="mt-10">(. . . . . . . . . . . . . . . . . . . . .)</div>
            </div>
        </div>
        <div class="w-full text-center">Disaksikan oleh</div>
        <div class="w-full flex">
            <div class="w-1/3 text-center">
                <div>BLC/OS</div>
                <div class="mt-10">(. . . . . . . . . . . . . . . . . . . . .)</div>
            </div>
            <div class="w-1/3 text-center">
                <div>BAM/BAS</div>
                <div class="mt-10">(. . . . . . . . . . . . . . . . . . . . .)</div>
            </div>
            <div class="w-1/3 text-center">
                <div>APJ*</div>
                <div class="mt-10">(. . . . . . . . . . . . . . . . . . . . .)</div>
            </div>
        </div>
        <div>
            <u><strong>Note:</strong></u>
            <div>* Jika produk obat maka wajib ditandatangani oleh APJ</div>
            <ul>
                <li>Wajib melampirkan list produk yang diserahterimakan</li>
                <li>Wajib melampirkan foto saat serah terima produk</li>
                <li>Wajib melampirkan PO Retur, Surat Pengajuan Retur (SPR), dan dokumen lainnya</li>
            </ul>
            <!-- <div>Wajib melampirkan list produk yang diserahterimakan</div>
            <div>Wajib melampirkan foto saat serah terima produk</div>
            <div>Wajib melampirkan PO Retur, Surat Pengajuan Retur (SPR), dan dokumen lainnya</div> -->
        </div>
    </div>
</template>
<script>
import logo from "@/assets/images/logo/logo-sdn.png"
import moment from "moment"
export default {
    mounted() {
        this.id = this.$route.params.id
        if (this.id) {
            this.print()
        }
    },
    data() {
        return {
            id: null,
            logo: logo,
            code: null,
            date: null,
            warehouse: {},
            srcWarehouseArea: {},
            destWarehouseArea: {},
            skuLines: [
                {
                    line_id: 0,
                    sku_code: null,
                    item_code: null,
                    item_name: null,
                    batch: null,
                    batch_only: null,
                    exp_date: null,
                    qty: null,
                    unit: null,
                    qty_uom: 0,
                }
            ],
            rawTotalQty: 0,
            totalQty: null,
        }
    },
    methods: {
        async print() {
            await this.getData()
            await window.print()
        },
        async getData() {
            this.$vs.loading();
            const resp = await this.$http.get("api/wms/v1/wms/outbound/supplier-return/handover/"+this.id)
            if (resp.code == 200) {
                this.code = resp.data.code
            }
            this.$vs.loading.close();
        },
        handleUpdateFormat(value) {
            let cleanValue = value.toString().replace(/\D/g, '');
            return new Intl.NumberFormat('id-ID').format(cleanValue);
        },
    },
    computed: {
        formatDate: function () {
            return (val) => moment(val).format("YYYY-MM-DD")
        }
    },
}
</script>
